// @flow
import React, {PureComponent, type Node} from 'react';
import classnames from 'classnames';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import Home from '@material-ui/icons/Home';
import Store from '@material-ui/icons/Store';
import Business from '@material-ui/icons/Business';
import LocationCity from '@material-ui/icons/LocationCity';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {type Site_BuildingType} from 'pb/cwn/site';
import {colors, type Color} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  option: {
    display: 'flex',
    border: `1px solid ${colors.grey6}`,
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'box-shadow'],
      {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.ease,
      },
    ),
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      flex: `0 0 calc(25% - ${(theme.spacing(2.5) * 3) / 4}px)`,
      padding: theme.spacing(2),
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 176,
    },
    '&:not(:first-child)': {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2.5),
      },
    },
    '&$selected': {
      borderColor: 'transparent',
      backgroundColor: 'currentColor',
    },
    '&:not($selected)': {
      '&:hover': {
        borderColor: 'transparent',
        backgroundColor: 'currentColor',
        boxShadow: theme.shadows[8],
        zIndex: 1,
      },
    },
  },
  selected: {},
  iconCircle: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  icon: {
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 44,
    },
  },
  optionText: {
    color: theme.palette.text.primary,
    fontSize: 18,
    textAlign: 'center',
    '&$selected': {
      fontWeight: 600,
    },
  },
});

type Props = {|
  buildingType: ?Site_BuildingType,
  onBuildingTypeSelect(?Site_BuildingType): void,
  // injected
  classes: Classes<typeof styles>,
|};

type State = {||};

class BuildingTypeSelect extends PureComponent<Props, State> {
  renderItem(type: Site_BuildingType, icon: Node, text: string, color: Color) {
    const {buildingType, onBuildingTypeSelect, classes} = this.props;
    const selected = type === buildingType;

    return (
      <div
        onClick={() => onBuildingTypeSelect(type)}
        className={classnames(classes.option, {
          [classes.selected]: selected,
        })}
        style={{color: lighten(color, 0.9)}}>
        <div className={classes.iconCircle} style={{backgroundColor: color}}>
          {icon}
        </div>
        <Typography
          className={classnames(classes.optionText, {
            [classes.selected]: selected,
          })}>
          {text}
        </Typography>
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        {this.renderItem(
          'SINGLE_FAMILY_HOME',
          <Home className={classes.icon} />,
          'Single Family Home',
          colors.java1,
        )}
        {this.renderItem(
          'IN_LAW',
          <Store className={classes.icon} />,
          'Cottage / In-law Unit',
          colors.chablis1,
        )}
        {this.renderItem(
          'MDU',
          <Business className={classes.icon} />,
          'Multi-unit Building',
          colors.portage1,
        )}
        {this.renderItem(
          'COMMERCIAL',
          <LocationCity className={classes.icon} />,
          'Commercial Building',
          colors.carrot1,
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BuildingTypeSelect);
