// @flow
import React, {PureComponent} from 'react';
import {flowRight as compose} from 'lodash';
import Link from 'next/link';
import {withRouter, type Router} from 'next/router';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

import {withStyles, type Classes, type Theme} from 'ui';
import {withStore, type Store} from 'store';
import {colors, dimensions} from 'theme/v2';
import {FullWidthContent} from 'layout';
import Logo from 'logo';
import {HEADER_BANNER_HEIGHT} from 'header';
import CheckAddressForm, {type Step} from './CheckAddressForm';
import FadeIn from './FadeIn';

const styles = (theme: Theme) => ({
  root: {
    overflow: 'hidden',
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    transition: [
      theme.transitions.create(['transform', 'opacity'], {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.ease,
      }),
      theme.transitions.create(['top'], {
        duration: theme.transitions.duration.default,
        easing: theme.transitions.easing.ease,
      }),
    ],
    '&:not($isOpen)': {
      pointerEvents: 'none',
      transform: 'translate3d(0, 100%, 0)',
      opacity: 0,
    },
    '&$isOpen': {
      transform: 'translate3d(0, 0%, 0)',
      opacity: 1,
    },
    '&$isPromoShown': {
      top: HEADER_BANNER_HEIGHT,
      height: `calc(100% - ${HEADER_BANNER_HEIGHT}px)`,
    },
  },
  isOpen: {},
  isPromoShown: {},
  header: {
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      height: dimensions.headerHeight.smDown,
    },
    [theme.breakpoints.up('md')]: {
      height: dimensions.headerHeight.mdUp,
    },
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    transitionDelay: '0.25s',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2),
      // lines the bottom of "common" with bottom of menu icon
      marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(3),
    },
  },
  logo: {
    height: '100%',
  },
  close: {
    cursor: 'pointer',
    color: colors.bossanova1,
    transitionDelay: '0.25s',
    fontSize: 30,
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    maxHeight: '100%',
    position: 'relative',
  },
  contentContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

type Props = {
  // injected
  router: Router,
  store: Store,
  classes: Classes<typeof styles>,
};

type State = {
  isOpen: boolean,
  step: Step,
};

function stepParamValue(step: Step): string {
  switch (step) {
    case 'ADDRESS':
      return 'address';
    case 'PROPERTY_INFO':
      return 'property-info';
    case 'CONTACT_INFO':
      return 'contact-info';
    case 'READY_TO_SUBMIT':
      return 'ready-to-submit';
    case 'SUBMITTING':
      return 'submitting';
    default:
      return '';
  }
}

class CheckAddressModal extends PureComponent<Props, State> {
  state = {
    isOpen: false,
    step: 'ADDRESS',
  };

  componentDidMount() {
    const {router} = this.props;
    router.events.on('routeChangeComplete', this.handleRouteChange);
    document.addEventListener('keyup', this.handleKeyup);
    this.handleRouteChange();
  }

  componentWillUnmount() {
    const {router} = this.props;
    router.events.off('routeChangeComplete', this.handleRouteChange);
    document.removeEventListener('keyup', this.handleKeyup);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {isOpen, step} = this.state;
    const {isOpen: prevIsOpen, step: prevStep} = prevState;

    if (isOpen !== prevIsOpen || step !== prevStep) {
      this.updateStepParam();
    }
  }

  handleRouteChange = () => {
    const {isOpen} = this.state;
    const params = new URLSearchParams(document.location.search);
    const nextIsOpen = Boolean(params.get('check'));

    if (isOpen !== nextIsOpen) {
      this.setState({isOpen: nextIsOpen});
    }
  };

  handleKeyup = (e: KeyboardEvent) => {
    const {isOpen} = this.state;
    const isEscape = e.keyCode === 27;
    const isInput =
      e.target instanceof HTMLElement && e.target.tagName === 'INPUT';

    if (isEscape && isOpen && !isInput) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const {router} = this.props;
    const params = new URLSearchParams(document.location.search);

    params.delete('check');
    const paramsStr = params.toString();
    router.replace(
      paramsStr ? `${router.pathname}?${paramsStr}` : router.pathname,
    );
  };

  handleStepChange = (step: Step) => {
    this.setState({step});
  };

  // this is used to trigger a pageview in GA
  updateStepParam = () => {
    const {router} = this.props;
    const {isOpen, step} = this.state;

    const params = new URLSearchParams(document.location.search);
    if (isOpen) {
      params.set('step', stepParamValue(step));
    } else {
      params.delete('step');
    }

    const paramsStr = params.toString();
    router.replace(
      paramsStr ? `${router.pathname}?${paramsStr}` : router.pathname,
    );
  };

  render() {
    const {store, classes} = this.props;
    const {isOpen} = this.state;

    return (
      <div
        className={classnames(classes.root, {
          [classes.isOpen]: isOpen,
          [classes.isPromoShown]: store.state.isPromoShown,
        })}>
        <FullWidthContent
          className={classes.header}
          contentClassName={classes.headerContent}>
          <FadeIn show={isOpen}>
            {className => (
              <Link href="/" passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  target="_top"
                  className={classnames(className, classes.logoContainer)}>
                  <Logo className={classes.logo} />
                </a>
              </Link>
            )}
          </FadeIn>
          <FadeIn show={isOpen}>
            {className => (
              <CloseIcon
                className={classnames(className, classes.close)}
                onClick={this.handleClose}
              />
            )}
          </FadeIn>
        </FullWidthContent>
        <FullWidthContent
          className={classes.content}
          contentClassName={classes.contentContent}>
          <CheckAddressForm
            showing={isOpen}
            onStepChange={this.handleStepChange}
          />
        </FullWidthContent>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withStore,
)(CheckAddressModal);
