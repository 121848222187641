// @flow
import React, {PureComponent} from 'react';

import {TextField, withStyles, type Classes, type Theme} from 'ui';
import {PhoneNumberFormatInput} from 'phone';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  field: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.only('xs')]: {
      flex: '1 1 100%',
    },
    [theme.breakpoints.up('sm')]: {
      flex: '0 1 calc(50% - 12px)',
    },
  },
});

type Props = {|
  name: string,
  onNameChange(string): void,
  email: string,
  onEmailChange(string): void,
  phone: string,
  onPhoneChange(string): void,
  // injected
  classes: Classes<typeof styles>,
|};

type State = {||};

class ContactInfoInput extends PureComponent<Props, State> {
  handleNameChange = (e: SyntheticInputEvent<>) => {
    this.props.onNameChange(e.target.value);
  };

  handleEmailChange = (e: SyntheticInputEvent<>) => {
    this.props.onEmailChange(e.target.value);
  };

  handlePhoneChange = (e: SyntheticInputEvent<>) => {
    this.props.onPhoneChange(e.target.value.replace(/[^\d]/g, '').slice(0, 10));
  };

  render() {
    const {classes, name, email, phone} = this.props;

    return (
      <div className={classes.root}>
        <TextField
          value={name}
          onChange={this.handleNameChange}
          label="First and last name"
          InputLabelProps={{required: false}}
          className={classes.field}
          required
        />
        <TextField
          value={email}
          onChange={this.handleEmailChange}
          type="email"
          label="Email address"
          InputLabelProps={{required: false}}
          className={classes.field}
          required
        />
        <TextField
          value={phone}
          onChange={this.handlePhoneChange}
          type="tel"
          label="Phone number"
          InputLabelProps={{required: false}}
          className={classes.field}
          InputProps={{inputComponent: PhoneNumberFormatInput}}
          required
        />
      </div>
    );
  }
}

export default withStyles(styles)(ContactInfoInput);
