// @flow
export type Cookie = {|
  key: string,
  value: string,
  expires: ?Date,
  path: ?string,
  domain: ?string,
|};

export function encode(cookie: Cookie): string {
  const parts = [`${cookie.key}=${cookie.value}`];

  if (cookie.expires) {
    parts.push(`Expires=${cookie.expires.toString()}`);
  }

  if (cookie.path) {
    parts.push(`Path=${cookie.path}`);
  }

  if (cookie.domain) {
    parts.push(`Domain=${cookie.domain}`);
  }

  return parts.join('; ');
}

export function parse(cookieStr: string): Cookie {
  const [[key, value], ...params] = cookieStr
    .split(';')
    .map(kv => kv.trim().split('='));

  let path, expires, domain;

  for (const [paramKey, paramValue] of params) {
    switch (paramKey.toLowerCase()) {
      case 'path':
        path = paramValue;
        break;
      case 'expires':
        expires = new Date(paramValue);
        break;
      case 'domain':
        domain = paramValue;
        break;
      default:
        break;
    }
  }

  return {key, value, path, expires, domain};
}

export function set(cookie: Cookie) {
  document.cookie = encode(cookie);
}

export function get(key: string): ?Cookie {
  for (const cookieStr of document.cookie.split(';')) {
    const cookie = parse(cookieStr);
    if (cookie.key === key) {
      return cookie;
    }
  }
}

export function unset(cookie: $Shape<Cookie>) {
  set({
    key: cookie.key,
    value: '',
    path: cookie.path || '/',
    expires: new Date(Date.now() - 1000),
    domain: cookie.domain,
  });
}

export function replace(cookie: Cookie) {
  unset(cookie);
  set(cookie);
}
