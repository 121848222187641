// @flow
import React, {type Node} from 'react';

import {Page} from 'layout';

type Props = {
  className?: string,
  children: Node,
};

function SitePage({className, children}: Props) {
  return <Page className={className}>{children}</Page>;
}

export default SitePage;