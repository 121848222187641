// @flow
import React, {PureComponent} from 'react';

import {NativeSelect, withStyles, type Classes, type Theme} from 'ui';
import {
  type Site_NumberOfUnits,
  Site_NumberOfUnitsValues,
  Site_NumberOfUnitsValue,
} from 'pb/cwn/site';

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
});

type Props = {|
  numberOfUnits: Site_NumberOfUnits,
  onNumberOfUnitsSelect(Site_NumberOfUnits): void,
  // injected
  classes: Classes<typeof styles>,
|};

type State = {||};

class NumberOfUnitsSelect extends PureComponent<Props, State> {
  handleChange = (e: SyntheticInputEvent<>) => {
    const {onNumberOfUnitsSelect} = this.props;

    onNumberOfUnitsSelect(
      Site_NumberOfUnitsValue(Site_NumberOfUnitsValues[e.target.value]),
    );
  };

  render() {
    const {classes, numberOfUnits} = this.props;

    return (
      <NativeSelect
        className={classes.root}
        value={numberOfUnits}
        onChange={this.handleChange}>
        <option value="ONE" />
        <option value="TWO_TO_FOUR">2-4</option>
        <option value="FIVE_TO_ELEVEN">5-11</option>
        <option value="TWELVE_TO_TWENTY">12-20</option>
        <option value="TWENTY_ONE_PLUS">21+</option>
      </NativeSelect>
    );
  }
}

export default withStyles(styles)(NumberOfUnitsSelect);
