// @flow
import {PureComponent, type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => {
  return {
    root: {
      transitionProperty: ['opacity', 'transform'],
      transitionDuration: '0.25s',
      transitionTimingFunction: 'ease-out',
      '&:not($showing)': {
        opacity: 0,
        transform: `translate3d(0, ${theme.spacing(1)}px, 0)`,
      },
      '&$showing': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    showing: {},
  };
};

type Props = {|
  show: boolean,
  children(className: string): Node,
  // injected
  classes: Classes<typeof styles>,
|};

type State = {|
  showing: boolean,
|};

class FadeIn extends PureComponent<Props, State> {
  state = {
    showing: false,
  };

  componentDidMount() {
    // fade-in on mount
    this.updateShowing();
  }

  componentDidUpdate(prev: Props) {
    const {show} = this.props;

    if (prev.show !== show) {
      this.updateShowing();
    }
  }

  updateShowing() {
    // delay for css transitions to take effect
    setTimeout(() => this.setState({showing: this.props.show}));
  }

  render() {
    const {show, classes, children} = this.props;
    const {showing} = this.state;

    if (!show) {
      return null;
    }

    return children(classnames(classes.root, {[classes.showing]: showing}));
  }
}

export default withStyles(styles)(FadeIn);
