// @flow
import React, {PureComponent, createRef} from 'react';
import debounce from 'lodash/debounce';
import compose from 'lodash/flowRight';
import classnames from 'classnames';

import {TextField, Typography, withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {
  withGoogleMapsAPI,
  type AddressComponentType,
  type GoogleMapsAPI,
  type GeocoderResult,
} from 'google/maps';
import {withLogger, type Logger} from 'log';
import {signupURL} from 'config';

const VALID_ADDRESS_TYPES: AddressComponentType[] = [
  'street_address',
  'premise',
  'subpremise',
  'floor',
  'post_box',
  'room',
  'street_number',
  'establishment',
  'point_of_interest',
  // 'route',
];

const MAX_AUTO_COMPLETE_RESULTS = 5;

// Bounds the Bay Area markets we currently serve
const DEFAULT_BOUNDS = {
  south: 37.639267,
  west: -122.417711,
  north: 37.888989,
  east: -122.032769,
};

// default js % can't handle negative numbers...
function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

// dont show 'USA' for local addresses
function getFormattedAddress(result: GeocoderResult): string {
  return result.formatted_address.replace(', USA', '');
}

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  address: {
    flex: '1 1 auto',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
    '&$addressSelected': {
      '& $input': {
        color: [colors.grey1, '!important'],
        '&:hover': {
          cursor: ['text', '!important'],
        },
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  addressField: {
    flex: '1 1 auto',
  },
  addressSelected: {},
  autoCompleteText: {
    position: 'absolute',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    zIndex: 1,
    pointerEvents: 'none',
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: -0.5,
    paddingTop: 17,
    paddingLeft: 30,
    opacity: 0.5,
  },
  subpremiseField: {
    transitionProperty: ['width'],
    transitionDuration: '0.15s',
    transitionTimingFunction: 'ease-out',
    '&:not($addressSelected)': {
      width: '0%',
      overflow: 'hidden',
    },
    '&$addressSelected': {
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '33%',
      },
    },
  },
  formControl: {
    height: theme.spacing(8),
    border: 'none',
    backgroundColor: colors.grey8,
    transitionProperty: ['background-color', 'box-shadow'],
    transitionDuration: '0.15s',
    transitionTimingFunction: 'ease-out',
    '&:hover': {
      boxShadow: theme.shadows[8],
      zIndex: 1,
    },
  },
  focused: {
    boxShadow: theme.shadows[12],
    backgroundColor: colors.white,
    '&:hover': {
      boxShadow: theme.shadows[12],
    },
    zIndex: 1,
  },
  input: {
    fontSize: 20,
    textIndent: theme.spacing(2),
    fontWeight: 500,
  },
  results: {
    position: 'absolute',
    left: 0,
    zIndex: 1,
    top: `calc(100% + ${theme.spacing(1)}px)`,
    width: '100%',
  },
  result: {
    height: 58,
    width: '100%',
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(0.5),
    boxShadow: theme.shadows[8],
    '&:not($highlighted)': {
      backgroundColor: colors.white,
    },
  },
  highlighted: {
    backgroundColor: colors.grey8,
  },
  resultText: {
    fontSize: 20,
    letterSpacing: -0.5,
    '&$highlighted': {
      fontWeight: 500,
    },
    // attempted fix for AUTO-1447 - android sometimes tries to
    // open maps app when you tap on an address
    pointerEvents: 'none',
  },
  addressHelp: {
    width: '100%',
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addressHelpLink: {
    textAlign: 'right',
    textDecoration: 'none',
    color: [colors.grey4, '!important'],
    fontSize: 13,
    '&:hover': {
      color: [colors.grey3, '!important'],
    },
  },
});

type Props = {|
  showing: boolean,
  geocodedAddress: ?GeocoderResult,
  onGeocodedAddressSelect(?GeocoderResult): void,
  subpremise: string,
  onSubpremiseChange(string): void,

  // injected
  googleMapsAPI: GoogleMapsAPI,
  logger: Logger,
  classes: Classes<typeof styles>,
|};

type State = {|
  address: string,
  results: GeocoderResult[],
  highlightedIndex: number,
  addressFocused: boolean,
  addressHelpHovered: boolean,
  dirty: boolean,
|};

class AddressInput extends PureComponent<Props, State> {
  state = {
    address: '',
    results: [],
    highlightedIndex: 0,
    addressFocused: false,
    addressHelpHovered: false,
    dirty: false,
  };

  addressInput = createRef();
  subpremiseInput = createRef();

  componentDidUpdate(prev: Props) {
    const {showing} = this.props;
    const {address} = this.state;
    const didShow = showing && !prev.showing;
    const input = this.addressInput && this.addressInput.current;

    if (didShow && input && !address.length) {
      // delay the focus so iOS doesn't break scroll position of fixed element
      setTimeout(() => input.focus(), 400);
    }
  }

  geocodeAddress = async () => {
    const {googleMapsAPI, logger} = this.props;
    const {address} = this.state;
    let results = [];

    if (address) {
      try {
        results = await googleMapsAPI.geocode({
          address,
          bounds: DEFAULT_BOUNDS,
        });
      } catch (e) {
        if (e.message.includes('OVER_QUERY_LIMIT')) {
          logger.warn(e.message);
        } else {
          logger.error(e.message, e.stack);
        }
      }
    }

    const filtered = results
      .filter(result =>
        result.types.some(type =>
          VALID_ADDRESS_TYPES.some(validType => type === validType),
        ),
      )
      .slice(0, MAX_AUTO_COMPLETE_RESULTS);

    this.setState(state => {
      if (address !== state.address) {
        // text has changed, another search has started
        return {};
      }
      return {results: filtered, highlightedIndex: 0};
    });
  };

  geocodeAddressDebounced = debounce(this.geocodeAddress, 200, {leading: true});

  handleAddressChange = (e: SyntheticInputEvent<>) => {
    const {geocodedAddress} = this.props;
    if (geocodedAddress) {
      this.handleGeocodedAddressSelect(null);
    }

    this.setState(
      {address: e.target.value, dirty: true},
      this.geocodeAddressDebounced,
    );
  };

  handleSubpremiseChange = (e: SyntheticInputEvent<>) => {
    this.props.onSubpremiseChange(e.target.value);
  };

  handleGeocodedAddressSelect = (result: ?GeocoderResult) => {
    const {onGeocodedAddressSelect, onSubpremiseChange} = this.props;
    onGeocodedAddressSelect(result);

    if (result) {
      this.setState({
        address: getFormattedAddress(result),
        addressFocused: false,
      });

      for (const component of result.address_components) {
        if (component.types.indexOf('subpremise') !== -1) {
          onSubpremiseChange(component.long_name);
          return;
        }
      }
    }

    onSubpremiseChange('');
  };

  handleTab = () => {
    const {results, highlightedIndex} = this.state;
    const highlightedResult = results[highlightedIndex];

    if (highlightedResult) {
      this.handleGeocodedAddressSelect(highlightedResult);
    }
  };

  handleEnter = () => {
    const {results, highlightedIndex} = this.state;
    const highlightedResult = results[highlightedIndex];

    if (highlightedResult) {
      this.handleGeocodedAddressSelect(highlightedResult);
    }

    if (this.subpremiseInput && this.subpremiseInput.current) {
      this.subpremiseInput.current.focus();
    }
  };

  handleAddressKeyDown = (e: SyntheticKeyboardEvent<>) => {
    const {results, highlightedIndex} = this.state;
    const isTab = e.keyCode === 9;
    const isEnter = e.keyCode === 13;
    const isDown = e.keyCode === 40;
    const isUp = e.keyCode === 38;

    if (isTab) {
      this.handleTab();
    }

    if (isEnter) {
      this.handleEnter();
    }

    if (isDown) {
      this.setState({
        highlightedIndex: mod(highlightedIndex + 1, results.length),
      });
    }

    if (isUp) {
      this.setState({
        highlightedIndex: mod(highlightedIndex - 1, results.length),
      });
    }
  };

  handleAddressFocus = () => {
    this.setState({addressFocused: true});
  };

  handleAddressBlur = () => {
    this.setState({addressFocused: false});
  };

  handleResultMouseEnter = (index: number) => {
    this.setState({highlightedIndex: index});
  };

  handleResultMouseDown = (index: number) => {
    const {results} = this.state;
    const selected = results[index];

    if (selected) {
      this.handleGeocodedAddressSelect(selected);
      setTimeout(() => {
        if (this.subpremiseInput && this.subpremiseInput.current) {
          this.subpremiseInput.current.focus();
        }
      });
    }
  };

  handleAddressHelpMouseEnter = () => {
    this.setState({addressHelpHovered: true});
  };

  handleAddressHelpMouseLeave = () => {
    this.setState({addressHelpHovered: false});
  };

  handleAddressFieldClick = () => {
    const {geocodedAddress} = this.props;

    if (!geocodedAddress) {
      // text field is enabled, defer to default behavior
      return;
    }

    // text field is disabled (to stop autocomplete), so go
    // back to edit mode by clearing current geocode selection
    this.handleGeocodedAddressSelect();
    setTimeout(() => {
      if (this.addressInput && this.addressInput.current) {
        this.addressInput.current.focus();
        (this.addressInput.current: any).select &&
          (this.addressInput.current: any).select();
      }
    });
  };

  renderAutoCompleteText() {
    const {classes} = this.props;
    const {address, results, highlightedIndex} = this.state;
    const highlightedResult = results[highlightedIndex];
    const formatted =
      highlightedResult && getFormattedAddress(highlightedResult);
    const mightBeScrollingRight = address.length >= 30;

    if (!address || !formatted || mightBeScrollingRight) {
      return null;
    }

    const punctuation = new RegExp(/,|\./, 'g');
    const typed = address.replace(punctuation, '').toLowerCase();
    const result = formatted.replace(punctuation, '').toLowerCase();
    const matchesTypedSoFar = typed === result.slice(0, typed.length);

    if (!matchesTypedSoFar) {
      return null;
    }

    // autocomplete text should replace the beginning of the full formatted
    // address with what the user has typed so far, ignoring case and punctuation
    let formattedIndex = 0;
    for (let i = 0; i < address.length; i++) {
      const typedChar = address[i];
      const formattedChar = formatted[formattedIndex];

      if (typedChar.toLowerCase() === formattedChar.toLowerCase()) {
        formattedIndex++;
      }
      if (punctuation.test(typedChar) && !punctuation.test(formattedChar)) {
        // keep current position
      }
      if (!punctuation.test(typedChar) && punctuation.test(formattedChar)) {
        // skip one ahead
        formattedIndex += 2;
      }
    }

    const autoCompleteText = address + formatted.slice(formattedIndex);

    return (
      // contenteditable makes the text get rendered the same as <input/>
      // since some kerning pairs are different from <p/> elements
      <Typography className={classes.autoCompleteText} contentEditable>
        {autoCompleteText}
      </Typography>
    );
  }

  renderResults() {
    const {classes, geocodedAddress} = this.props;
    const {
      results,
      highlightedIndex,
      addressFocused,
      addressHelpHovered,
      dirty,
    } = this.state;

    const showResults = addressFocused || addressHelpHovered;
    const showAddressHelp =
      dirty && (addressFocused || addressHelpHovered || !geocodedAddress);

    return (
      <div className={classes.results}>
        {showResults &&
          results.map((result: GeocoderResult, index: number) => {
            return (
              <div
                key={result.place_id}
                className={classnames(classes.result, {
                  [classes.highlighted]: highlightedIndex === index,
                })}
                onMouseEnter={() => this.handleResultMouseEnter(index)}
                onMouseDown={() => this.handleResultMouseDown(index)}>
                <Typography
                  className={classnames(classes.resultText, {
                    [classes.highlighted]: highlightedIndex === index,
                  })}
                  noWrap>
                  {getFormattedAddress(result)}
                </Typography>
              </div>
            );
          })}
        {showAddressHelp && (
          <div
            className={classes.addressHelp}
            onMouseEnter={this.handleAddressHelpMouseEnter}
            onMouseLeave={this.handleAddressHelpMouseLeave}>
            <Typography
              className={classes.addressHelpLink}
              component="a"
              href={`${signupURL}/onboard`}
              target="_top">
              Don't see your address?
            </Typography>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {classes, subpremise, geocodedAddress} = this.props;
    const {address} = this.state;
    return (
      <div className={classes.root}>
        <div
          className={classnames(classes.address, {
            [classes.addressSelected]: geocodedAddress,
          })}>
          <TextField
            // once the user has selected an address,
            // make this field disabled so it doesn't get
            // changed by browser autocomplete later
            disabled={Boolean(geocodedAddress)}
            onClick={this.handleAddressFieldClick}
            value={address}
            inputRef={(this.addressInput: any)}
            className={classes.addressField}
            InputProps={{
              inputProps: {
                // disable auto fill for chrome https://bugs.chromium.org/p/chromium/issues/detail?id=370363#c7
                // was 'off', 'new-password' may affect safari
                autoComplete: 'new-password',
              },
              classes: {
                formControl: classes.formControl,
                input: classes.input,
                focused: classes.focused,
              },
            }}
            onChange={this.handleAddressChange}
            onKeyDown={this.handleAddressKeyDown}
            onFocus={this.handleAddressFocus}
            onBlur={this.handleAddressBlur}
            placeholder="Street address"
          />
          {this.renderAutoCompleteText()}
          {this.renderResults()}
        </div>
        <TextField
          value={subpremise}
          inputRef={(this.subpremiseInput: any)}
          className={classnames(classes.subpremiseField, {
            [classes.addressSelected]: geocodedAddress,
          })}
          InputProps={{
            // 'new-password' may affect safari
            autoComplete: 'new-password',
            classes: {
              formControl: classes.formControl,
              input: classes.input,
              focused: classes.focused,
            },
          }}
          onChange={this.handleSubpremiseChange}
          placeholder="Apt., Suite, Building"
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withGoogleMapsAPI,
  withLogger,
)(AddressInput);
