// @flow
import React, {type Node} from 'react';
import Link from 'next/link';

type Props = {
  children: Node,
};

function CheckAddressLink({children}: Props) {
  let params;
  if (typeof window !== 'undefined') {
    params = new URLSearchParams(window.document.location.search);
  } else {
    params = new URLSearchParams();
  }
  params.append('check', 'true');
  const paramsStr = params.toString();

  return (
    <Link href={'?' + paramsStr} scroll={false} passHref>
      {children}
    </Link>
  );
}

export default CheckAddressLink;
