// @flow
import React, {PureComponent} from 'react';
import {colors} from 'theme/v2';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {CheckAddressLink} from 'site/CheckAddress';
import {Button, withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  button: {
    height: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: theme.spacing(50),
    width: '100%',
    border: `1px solid ${colors.grey5}`,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.grey8,
    },
  },
  buttonLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.only('xs')]: {
      color: colors.grey2,
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.common.black,
    },
  },
  arrow: {
    color: [colors.persian1, '!important'],
  },
});

type Props = {
  text: string,

  // injected
  classes: Classes<typeof styles>,
};

class CheckAddressBox extends PureComponent<Props> {
  render() {
    const {text, classes} = this.props;

    return (
      <CheckAddressLink>
        <Button
          component="a"
          className={classes.button}
          classes={{label: classes.buttonLabel}}>
          {text}
          <ArrowForwardIcon className={classes.arrow} />
        </Button>
      </CheckAddressLink>
    );
  }
}

export default withStyles(styles)(CheckAddressBox);
