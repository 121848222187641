// @flow
import React, {PureComponent, type Node} from 'react';
import classnames from 'classnames';
import {flowRight as compose} from 'lodash';

import {
  Button,
  CircularProgress,
  Divider,
  Typography,
  withStyles,
  type Classes,
  type Theme,
} from 'ui';
import {withStore, type Store} from 'store';
import {colors} from 'theme/v2';
import {type GeocoderResult} from 'google/maps';
import {type Site_BuildingType, type Site_NumberOfUnits} from 'pb/cwn/site';
import {GeoPoint} from 'pb/cwn/geocoder';
import {SignupV2} from 'pb/cwn/signup';
import {
  WebService,
  NewCustomerRequest,
  EmptyRequest,
  NewCustomerRequest_Form,
  UpdateSignupV2Request,
  CheckAddressRequest,
} from 'pb/cwn/web';
import {withWebService} from 'gae';
import {Analytics, withAnalytics} from 'analytics';
import {geocodeToAddress} from 'address';
import {withErrors, type OnError} from 'errors';
import * as Cookie from 'cookie';
import {dashboardPath, signupPath} from 'config';
import {withLogger, type Logger} from 'log';

import FadeIn from '../FadeIn';
import AddressInput from './AddressInput';
import BuildingTypeSelect from './BuildingTypeSelect';
import NumberOfUnitsSelect from './NumberOfUnitsSelect';
import ContactInfoInput from './ContactInfoInput';

const PROMO_COOKIE_KEY = '_promo_code';

const styles = (theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: 140,
    maxWidth: theme.spacing(115),
    width: '100%',
  },
  show: {},
  headlineContainer: {
    minHeight: theme.spacing(4),
  },
  headline: {
    transitionDelay: '0.5s',
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    height: 3,
    backgroundColor: colors.grey6,
  },
  sectionHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  sectionDescription: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  propertyInfo: {},
  buttonRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRowRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  errorTextContainer: {},
  errorText: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  checkButton: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  buttonProgress: {
    color: theme.palette.action.disabled,
  },
});

export type Step =
  | 'ADDRESS'
  | 'PROPERTY_INFO'
  | 'CONTACT_INFO'
  | 'READY_TO_SUBMIT'
  | 'SUBMITTING';

const STEP_ORDER = [
  'ADDRESS',
  'PROPERTY_INFO',
  'CONTACT_INFO',
  'READY_TO_SUBMIT',
  'SUBMITTING',
];

type Props = {|
  showing: boolean,
  onStepChange(Step): void,

  // injected
  analytics: Analytics,
  webService: WebService,
  store: Store,
  onError: OnError,
  logger: Logger,
  classes: Classes<typeof styles>,
|};

type State = {|
  geocodedAddress: ?GeocoderResult,
  subpremise: string,
  buildingType: ?Site_BuildingType,
  numberOfUnits: Site_NumberOfUnits,
  name: string,
  email: string,
  phone: string,
  submitting: boolean,
  error: ?Node,
  signupV2: ?SignupV2,
  isDirty: boolean,
  isUpdating: boolean,
|};

function getStep(state: State): Step {
  const {
    geocodedAddress,
    buildingType,
    numberOfUnits,
    name,
    email,
    phone,
    submitting,
  } = state;
  const needsToSelectNumberOfUnits =
    buildingType === 'MDU' && numberOfUnits === 'ONE';

  if (!geocodedAddress) {
    return 'ADDRESS';
  }

  if (!buildingType || needsToSelectNumberOfUnits) {
    return 'PROPERTY_INFO';
  }

  if (!name || !email || phone.length < 10) {
    return 'CONTACT_INFO';
  }

  if (!submitting) {
    return 'READY_TO_SUBMIT';
  }

  return 'SUBMITTING';
}

function getPromoFromCookie(): ?string {
  if (typeof document === 'undefined') {
    return;
  }

  const promoCookie = Cookie.get(PROMO_COOKIE_KEY);
  const promoCode =
    promoCookie && promoCookie.value && promoCookie.value.toUpperCase();

  return promoCode;
}

class CheckAddressForm extends PureComponent<Props, State> {
  state = {
    geocodedAddress: null,
    subpremise: '',
    buildingType: null,
    numberOfUnits: 'ONE',
    name: '',
    email: '',
    phone: '',
    submitting: false,
    signupV2: null,
    error: null,
    isDirty: false,
    isUpdating: false,
  };

  // tell backend to start qualifying this address now
  pushAddressCheck = async (geocoderResult: GeocoderResult) => {
    const {analytics, webService} = this.props;

    try {
      await webService.CheckAddress(
        new CheckAddressRequest({
          address: geocodeToAddress(geocoderResult),
          location: new GeoPoint({
            lat: geocoderResult.geometry.location.lat(),
            lng: geocoderResult.geometry.location.lng(),
          }),
        }),
      );
    } catch (e) {
      // don't show error to the user
      analytics.exception({
        description: 'CheckAddressForm.pushAddressCheck',
        fatal: false,
        message: e.message,
      });
    }
  };

  handleSubmit = async (e: SyntheticInputEvent<>) => {
    const {analytics, store, webService, onError, logger} = this.props;
    const {
      geocodedAddress: geocoderResult,
      subpremise,
      buildingType,
      numberOfUnits,
      name,
      email,
      phone,
      signupV2,
    } = this.state;

    e.preventDefault();

    if (
      !this.isAtOrBeyondStep('READY_TO_SUBMIT') ||
      !buildingType ||
      !geocoderResult
    ) {
      return;
    }

    const start = Date.now();

    this.setState({submitting: true, error: null});

    try {
      const req = new NewCustomerRequest();
      const form = new NewCustomerRequest_Form();
      const geocodedAddress = geocodeToAddress(geocoderResult);
      const promoCode = getPromoFromCookie();

      form.name = name;
      form.email = email;
      form.phone = phone;
      form.buildingType = buildingType;
      form.numberOfUnits = numberOfUnits;
      form.addressLine1 = `${geocodedAddress.street_address} ${
        geocodedAddress.route
      }`;
      form.addressLine2 = subpremise || geocodedAddress.subpremise;
      form.city = geocodedAddress.locality;
      form.state = geocodedAddress.administrativeAreaLevel1;
      form.zip = geocodedAddress.postalCode;

      req.geocodedAddress = geocodedAddress;
      req.geocodedLatLng = new GeoPoint({
        lat: geocoderResult.geometry.location.lat(),
        lng: geocoderResult.geometry.location.lng(),
      });
      req.form = form;
      req.referrer = document.referrer;
      if (!signupV2) {
        throw new Error('missing signupV2');
      }
      req.signup_v2 = signupV2;

      if (promoCode) {
        req.token = promoCode;
      }

      const res = await webService.NewCustomer(req);

      switch (res.result) {
        case 'FORM_ERROR':
          this.setState({submitting: false, error: res.form_error});
          break;
        case 'INVALID_PROMO':
          Cookie.unset({key: PROMO_COOKIE_KEY});
          store.dispatch({type: 'hideBanner'});
          this.setState({submitting: false, error: res.form_error});
          break;
        case 'ACCOUNT_EXISTS':
          this.setState({
            submitting: false,
            error: (
              <span>
                An account for this email already exists.{' '}
                <a href={`${dashboardPath}/login`} target="_top">
                  Click here to log in.
                </a>
              </span>
            ),
          });
          break;
        case 'CREATED_WITH_ERROR':
        case 'OK':
          if (!res.customer_id) {
            throw new Error('NewCustomer response missing CustomerId.');
          }
          Cookie.unset({key: PROMO_COOKIE_KEY});
          analytics.identify({
            customerID: res.customer_id,
            email,
          });

          const end = Date.now();

          logger.debug(
            `CheckAddressForm submit start=${start}; end=${end}; total=${end -
              start}ms;`,
          );

          window.top.location.href = `${signupPath}/onboard/checking-availability`;
          // keep the button spinning
          break;
        case 'INVALID':
        default:
          throw new Error('NewCustomer response returned INVALID.');
      }
    } catch (e) {
      onError({error: e, description: 'Signup.handleSubmit'});
      this.setState({
        error: 'An unknown error has occurred. Please try again.',
        submitting: false,
      });
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {onStepChange, showing} = this.props;
    const {isDirty, isUpdating, geocodedAddress} = this.state;
    const prevStep = getStep(prevState);
    const step = getStep(this.state);

    if (step !== prevStep) {
      onStepChange(step);
    }

    if (!prevProps.showing && showing) {
      this.createSignupV2();
    }
    if (isDirty && !isUpdating && geocodedAddress) {
      this.updateSignupV2();
    }
  }

  createSignupV2 = async () => {
    const {webService, onError} = this.props;
    this.setState({isUpdating: true});
    try {
      const res = await webService.CreateSignupV2(new EmptyRequest());
      const {status} = res;
      if (status !== 'OK') {
        throw new Error(`CreateSignupV2 request failed with status: ${status}`);
      }
      this.setState({isUpdating: false});
    } catch (e) {
      this.setState({isUpdating: false});
      onError({error: e, description: 'Signup.CreateSignupV2'});
    }
  };

  updateSignupV2 = async () => {
    const {webService, onError} = this.props;
    this.setState({isDirty: false, isUpdating: true});
    const {
      geocodedAddress,
      subpremise,
      buildingType,
      numberOfUnits,
      name,
      email,
      phone,
    } = this.state;
    try {
      const req = new UpdateSignupV2Request();
      let signupV2 = new SignupV2();
      if (!geocodedAddress) {
        return;
      }
      signupV2.address = geocodeToAddress(geocodedAddress);
      signupV2.subpremise = subpremise;
      if (buildingType) {
        signupV2.building_type = buildingType;
      }
      signupV2.number_of_units = numberOfUnits;
      signupV2.name = name;
      signupV2.email = email;
      signupV2.phone = phone;
      req.signup_v2 = signupV2;
      const {status} = await webService.UpdateSignupV2(req);
      if (status !== 'OK') {
        throw new Error(`UpdateSignupV2 request failed with status: ${status}`);
      }
      this.setState({isUpdating: false, signupV2});
    } catch (e) {
      this.setState({isUpdating: false});
      onError({error: e, description: 'Signup.UpdateSignupV2'});
    }
  };

  isAtOrBeyondStep(step: Step): boolean {
    return STEP_ORDER.indexOf(getStep(this.state)) >= STEP_ORDER.indexOf(step);
  }

  handleGeocodedAddressSelect = (geocodedAddress: ?GeocoderResult) => {
    this.setState({
      geocodedAddress,
      buildingType: null,
      numberOfUnits: 'ONE',
      isDirty: true,
    });

    if (geocodedAddress) {
      this.pushAddressCheck(geocodedAddress);
    }
  };

  handleSubpremiseChange = (subpremise: string) => {
    this.setState({subpremise, isDirty: true});
  };

  handleBuildingTypeSelect = (buildingType: ?Site_BuildingType) => {
    this.setState({buildingType, numberOfUnits: 'ONE', isDirty: true});
  };

  handleNumberOfUnitsSelect = (numberOfUnits: Site_NumberOfUnits) => {
    this.setState({numberOfUnits, isDirty: true});
  };

  handleNameChange = (name: string) => {
    this.setState({name, isDirty: true});
  };

  handleEmailChange = (email: string) => {
    this.setState({email, isDirty: true});
  };

  handlePhoneChange = (phone: string) => {
    this.setState({phone, isDirty: true});
  };

  renderPropertyInfo() {
    const {classes} = this.props;
    const {buildingType} = this.state;

    return (
      <FadeIn show={this.isAtOrBeyondStep('PROPERTY_INFO')}>
        {className => (
          <div className={classnames(className, classes.propertyInfo)}>
            <Divider className={classes.divider} />
            <Typography className={classes.sectionHeader} variant="body2">
              Property info
            </Typography>
            <Typography className={classes.sectionDescription} variant="body2">
              What type of home do you live in?
            </Typography>
            <BuildingTypeSelect
              buildingType={buildingType}
              onBuildingTypeSelect={this.handleBuildingTypeSelect}
            />
            {this.renderNumberOfUnitsSelect()}
          </div>
        )}
      </FadeIn>
    );
  }

  renderNumberOfUnitsSelect() {
    const {classes} = this.props;
    const {buildingType, numberOfUnits} = this.state;
    const show =
      buildingType === 'MDU' && this.isAtOrBeyondStep('PROPERTY_INFO');

    return (
      <FadeIn show={show}>
        {classNameInner => (
          <div className={classNameInner}>
            <Typography className={classes.sectionDescription} variant="body2">
              How many units are in the building?
            </Typography>
            <NumberOfUnitsSelect
              numberOfUnits={numberOfUnits}
              onNumberOfUnitsSelect={this.handleNumberOfUnitsSelect}
            />
          </div>
        )}
      </FadeIn>
    );
  }

  renderContactInfo() {
    const {classes} = this.props;
    const {name, email, phone} = this.state;

    return (
      <FadeIn show={this.isAtOrBeyondStep('CONTACT_INFO')}>
        {className => (
          <div className={classnames(className, classes.propertyInfo)}>
            <Divider className={classes.divider} />
            <Typography className={classes.sectionHeader} variant="body2">
              What's the best way to reach you?
            </Typography>
            <Typography className={classes.sectionDescription} variant="body2">
              If service isn't available for your home, we'll get in touch if we
              come to your neighborhood.
            </Typography>
            <ContactInfoInput
              name={name}
              onNameChange={this.handleNameChange}
              email={email}
              onEmailChange={this.handleEmailChange}
              phone={phone}
              onPhoneChange={this.handlePhoneChange}
            />
          </div>
        )}
      </FadeIn>
    );
  }

  renderButtonRow() {
    const {classes} = this.props;
    const {submitting, error} = this.state;

    return (
      <FadeIn show={this.isAtOrBeyondStep('CONTACT_INFO')}>
        {className => (
          <div className={classnames(className, classes.buttonRow)}>
            <div className={classes.buttonRowRow}>
              <div className={classes.errorTextContainer}>
                {error && (
                  <Typography color="error" className={classes.errorText}>
                    {error}
                  </Typography>
                )}
              </div>
              <Button
                className={classes.checkButton}
                type="submit"
                variant="contained"
                color="primary"
                width="small"
                size="large"
                disabled={submitting}>
                {submitting ? (
                  <CircularProgress
                    size={22}
                    className={classes.buttonProgress}
                  />
                ) : (
                  'check availability'
                )}
              </Button>
            </div>
          </div>
        )}
      </FadeIn>
    );
  }

  render() {
    const {showing, classes} = this.props;
    const {geocodedAddress, subpremise} = this.state;

    return (
      <form
        className={classes.root}
        onSubmit={this.handleSubmit}
        autoComplete="off">
        <div className={classes.headlineContainer}>
          <FadeIn show={showing}>
            {className => (
              <Typography
                variant="h5"
                align="center"
                className={classnames(className, classes.headline)}>
                Let's see if service is available where you live
              </Typography>
            )}
          </FadeIn>
        </div>
        <AddressInput
          showing={showing}
          geocodedAddress={geocodedAddress}
          onGeocodedAddressSelect={this.handleGeocodedAddressSelect}
          subpremise={subpremise}
          onSubpremiseChange={this.handleSubpremiseChange}
        />
        {this.renderPropertyInfo()}
        {this.renderContactInfo()}
        {this.renderButtonRow()}
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withAnalytics,
  withWebService,
  withErrors,
  withLogger,
  withStore,
)(CheckAddressForm);
